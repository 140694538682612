import React, { useState } from 'react';
import plusIcon from './images/Plus.svg';
import minusIcon from './images/Minus.svg';

function FAQ() {
    const [faq1, setFAQ1] = useState(false);
    const [faq2, setFAQ2] = useState(false);
    const [faq3, setFAQ3] = useState(false);

    return (
        <>
            <div id="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Do you have any Question?</h2>
                            <p>These are the frequently asked question, if you have any more question, We are here to help you.</p>
                            <a href="#contact" className='contact-us'>Contact us</a>
                        </div>

                        <div className="col-md-6 faq-questions-column">
                            <div className='faq-question-block'>
                                <div className='faq-question-heading' onClick={() => setFAQ1(!faq1)} style={faq1 ? { borderBottom: '1px solid rgb(219, 229, 230)' } : {}} >
                                    <img src={faq1 ? minusIcon : plusIcon} alt="plus icon" />
                                    <h6>Can I pay online?</h6>
                                </div>

                                <p style={faq1 ? { display: 'initial' } : { display: 'none' }}>Absolutly yes, our platform provide you with different payment method which include e-payment as well as cash also you can pay through apple pay.</p>
                            </div>

                            <div className='faq-question-block'>
                                <div className='faq-question-heading' onClick={() => setFAQ2(!faq2)} style={faq2 ? { borderBottom: '1px solid rgb(219, 229, 230)' } : {}} >
                                    <img src={faq2 ? minusIcon : plusIcon} alt="plus icon" />
                                    <h6>Can I get my order to my home?</h6>
                                </div>

                                <p style={faq2 ? { display: 'initial' } : { display: 'none' }}>
                                    Yes, some providers give enable the feature  where they can come
                                    to your place and give you the services, but you will have to pay extra for that
                                    but it  helps people in need for that.
                                </p>
                            </div>

                            <div className='faq-question-block'>
                                <div className='faq-question-heading' onClick={() => setFAQ3(!faq3)} style={faq3 ? { borderBottom: '1px solid rgb(219, 229, 230)' } : {}}>
                                    <img src={faq3 ? minusIcon : plusIcon} alt="plus icon" />
                                    <h6>How do I contact your team?</h6>
                                </div>

                                <p style={faq3 ? { display: 'initial' } : { display: 'none' }}>
                                    Our team is available 24/7 to contact with them, you can simply  call  us on our  number
                                    or contact us throught soical media or  even write for us in the form  here  is  the website
                                    we will get to you as soon as possible, keep in mind sometimes a process
                                    could take few hours to days.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FAQ;