import React from 'react';
import data from './data';

function Clubs() {
    const logos = data.filter((item) => item.title === "logo");

    return (
        <div className="container">

        </div>
    );
}

export default Clubs;