import React from "react";

function MarcoReus() {

  return (
    // https://www.soccerpro.com/product/2022-23-kids-puma-marco-reus-borussia-dortmund-home-jersey/
    // https://www.fanatics.com/international-clubs/bayern-munich/thomas-müller-bayern-munich-adidas-2022/23-home-replica-player-jersey-red/o-42092466+t-70853536+p-48293668+z-8-2747792707?_ref=p-ALP:m-GRID:i-r0c2:po-2
    <h1>Marco Rues</h1>
  );
}

export default MarcoReus;
