const data = [
    {
        id: 1,
        img: '/images/Marco Reus.png',
        name: 'Marco Reus',
        title: 'player',
        background: 'linear-gradient(225deg, orange, yellow)',
        number: '11',
        img0: '/images/Marco Reus Jersey/Marco Reus Jersey.png',
        img1: '/images/Marco Reus Jersey/1.jpg',
        img2: '/images/Marco Reus Jersey/2.jpg',
        img3: '/images/Marco Reus Jersey/3.jpg',
        img4: '/images/Marco Reus Jersey/4.jpg',
        img5: '/images/Marco Reus Jersey/5.jpg',
        img6: '/images/Marco Reus Jersey/6.jpg',
        link: '/players/Marco Reus'
    },
    {
        id: 2,
        img: '/images/Thomas Muller.png',
        name: 'Thomas Muller',
        title: 'player',
        background: 'linear-gradient(225deg, red, rgba(255, 0, 0, 0.27))',
        number: '25',
        img0: '/images/Thomas Muller Jersey/Thomas Muller Jersey.png',
        img1: '/images/Thomas Muller Jersey/1.jpg',
        img2: '/images/Thomas Muller Jersey/2.jpg',
        img3: '/images/Thomas Muller Jersey/3.jpg',
        img4: '/images/Thomas Muller Jersey/4.jpg',
        img5: '/images/Thomas Muller Jersey/5.jpg',
        img6: '/images/Thomas Muller Jersey/6.jpg',
        link: '/players/Thomas Muller'
    },
    {
        id: 3,
        img: '/images/Sadio Mane.png',
        name: 'Sadio Mane',
        title: 'player',
        background: 'linear-gradient(225deg, red, rgba(255, 0, 0, 0.27))',
        number: '17',
        img0: '/images/Sadio Mane Jersey/Sadio Mane Jersey.png',
        img1: '/images/Sadio Mane Jersey/1.jpg',
        img2: '/images/Sadio Mane Jersey/2.jpg',
        img3: '/images/Sadio Mane Jersey/3.jpg',
        img4: '/images/Sadio Mane Jersey/4.jpg',
        img5: '/images/Sadio Mane Jersey/5.jpg',
        img6: '/images/Sadio Mane Jersey/6.jpg',
        link: '/players/Sadio Mane'
    },
    {
        id: 4,
        img: '/images/Robert Lewandowski.png',
        name: 'Robert Lewandowski',
        title: 'player',
        background: 'linear-gradient(225deg, rgb(100, 0, 255), rgba(255, 0, 0, 0.27))',
        number: '9',
        img0: '/images/Robert Lewandowski Jersey/Robert Lewandowski Jersey.png',
        img1: '/images/Robert Lewandowski Jersey/1.jpg',
        img2: '/images/Robert Lewandowski Jersey/2.jpg',
        img3: '/images/Robert Lewandowski Jersey/3.jpg',
        img4: '/images/Robert Lewandowski Jersey/4.jpg',
        img5: '/images/Robert Lewandowski Jersey/5.jpg',
        img6: '/images/Robert Lewandowski Jersey/6.jpg'
    },
    {
        id: 5,
        img: '/images/Lionel Messi.png',
        name: 'Lionel Messi',
        title: 'player',
        background: 'linear-gradient(225deg, rgb(12, 12, 161), rgba(12, 12, 161, 0.27))',
        number: '30',
        img0: '/images/Lionel Messi Jersey/Lionel Messi Jersey.png',
        img1: '/images/Lionel Messi Jersey/1.jpg',
        img2: '/images/Lionel Messi Jersey/2.jpg',
        img3: '/images/Lionel Messi Jersey/3.jpg',
        img4: '/images/Lionel Messi Jersey/4.jpg',
        img5: '/images/Lionel Messi Jersey/5.jpg',
        img6: '/images/Lionel Messi Jersey/6.jpg'
    },
    {
        id: 6,
        img: '/images/Kylian Mbappe.png',
        name: 'Kylian Mbappe',
        title: 'player',
        background: 'linear-gradient(225deg, rgb(12, 12, 161), rgba(12, 12, 161, 0.27))',
        number: '7',
        img0: '/images/Kylian Mbappe Jersey/Kylian Mbappe Jersey.png',
        img1: '/images/Kylian Mbappe Jersey/1.jpg',
        img2: '/images/Kylian Mbappe Jersey/2.jpg',
        img3: '/images/Kylian Mbappe Jersey/3.jpg',
        img4: '/images/Kylian Mbappe Jersey/4.jpg',
        img5: '/images/Kylian Mbappe Jersey/5.jpg',
        img6: '/images/Kylian Mbappe Jersey/6.jpg'
    },
    {
        id: 7,
        img: '/images/Cristiano Ronaldo.png',
        name: 'Cristiano Ronaldo',
        title: 'player',
        background: 'linear-gradient(225deg, rgb(225, 0, 0), rgba(255, 0, 0, 0.27))',
        number: '7',
        img0: '/images/Cristiano Ronaldo Jersey/Cristiano Ronaldo Jersey.png',
        img1: '/images/Cristiano Ronaldo Jersey/1.jpg',
        img2: '/images/Cristiano Ronaldo Jersey/2.jpg',
        img3: '/images/Cristiano Ronaldo Jersey/3.jpg',
        img4: '/images/Cristiano Ronaldo Jersey/4.jpg',
        img5: '/images/Cristiano Ronaldo Jersey/5.jpg',
        img6: '/images/Cristiano Ronaldo Jersey/6.jpg'
    },
    {
        id: 8,
        img: '/images/Erling Haaland.png',
        name: 'Erling Haaland',
        title: 'player',
        background: 'linear-gradient(225deg, rgb(0, 175, 255), rgba(0, 175, 255, 0.27))',
        number: '9',
        img0: '/images/Erling Haaland Jersey/Erling Haaland Jersey.png',
        img1: '/images/Erling Haaland Jersey/1.jpg',
        img2: '/images/Erling Haaland Jersey/2.jpg',
        img3: '/images/Erling Haaland Jersey/3.jpg',
        img4: '/images/Erling Haaland Jersey/4.jpg',
        img5: '/images/Erling Haaland Jersey/5.jpg',
        img6: '/images/Erling Haaland Jersey/6.jpg'
    },
    {
        id: 9,
        img: '/images/Mohamed Salah.png',
        name: 'Mohamed Salah',
        title: 'player',
        background: 'linear-gradient(225deg, rgb(175, 0, 0), rgba(175, 0, 0, 0.27))',
        number: '11',
        img0: '/images/Mohamed Salah Jersey/Mohamed Salah Jersey.png',
        img1: '/images/Mohamed Salah Jersey/1.jpg',
        img2: '/images/Mohamed Salah Jersey/2.jpg',
        img3: '/images/Mohamed Salah Jersey/3.jpg',
        img4: '/images/Mohamed Salah Jersey/4.jpg',
        img5: '/images/Mohamed Salah Jersey/5.jpg',
        img6: '/images/Mohamed Salah Jersey/6.jpg'
    },
    // {
    //     id: 10,
    //     img: '/images/Karim Benzema.jpg',
    //     name: 'Karim Benzema',
    //     title: 'player'
    // },
    {
        id: 11,
        img: '/images/BVB Home Jersey.jpg',
        name: 'BVB Home Jersey',
        title: 'BVB Jersey',
        price: '$129.99'
    },
    {
        id: 12,
        img: '/images/BVB Away Jersey.jpg',
        name: 'BVB Away Jersey',
        title: 'BVB Jersey',
        price: '$89.99'
    },
    {
        id: 13,
        img: '/images/BVB 3rd Jersey.jpg',
        name: 'BVB 3rd Jersey',
        title: 'BVB Jersey',
        price: '$79.99'
    },
    {
        id: 14,
        img: '/images/FC Bayern Home Jersey.jpg',
        name: 'FCB Home Jersey',
        title: 'FC Bayern Jersey',
        price: '$83.99'
    }
    ,
    {
        id: 15,
        img: '/images/FC Bayern Away Jersey.jpg',
        name: 'FCB Away Jersey',
        title: 'FC Bayern Jersey',
        price: '$75.59'
    },
    {
        id: 16,
        img: '/images/FC Bayern 3rd Jersey.jpg',
        name: 'FCB 3rd Jersey',
        title: 'FC Bayern Jersey',
        price: '$58.78'
    },
    {
        id: 17,
        img: '/images/BVB Goalkeeper Kit.PNG',
        name: 'BVB Goalkeeper Kit',
        price: '$64.99'
    },
    {
        id: 18,
        img: '/images/BVB Jersey Pants.PNG',
        name: 'BVB Jersey Pants',
        price: '$39.99'
    },
    {
        id: 19,
        img: '/images/BVB Socks.PNG',
        name: 'BVB Socks',
        price: '$17.99'
    },
    {
        id: 20,
        img: '/images/BVB Cap.PNG',
        name: 'BVB Cap',
        price: '$14.99'
    },
    {
        id: 21,
        img: '/images/Thomas Muller Cap.PNG',
        name: 'Thomas Muller Cap',
        price: '$21.01'
    },
    {
        id: 22,
        img: '/images/Sadio Mane Cap.PNG',
        name: 'Sadio Mane Cap',
        price: '$18.91'
    },
    {
        id: 23,
        img: '/images/FCB PS5 Controller.PNG',
        name: 'FCB PS5 Controller',
        price: '$20.97'
    },
    {
        id: 24,
        img: '/images/FCB Men T-Shirt.PNG',
        name: 'FCB Men T-Shirt',
        price: '$25.17'
    },
    {
        id: 25,
        img: '/images/BVB logo.webp',
        name: 'BVB logo',
        title: 'logo'
    },
    {
        id: 26,
        img: '/images/FC Bayern logo.webp',
        name: 'FC Bayern logo',
        title: 'logo'
    },
    {
        id: 27,
        img: '/images/PSG Home Jersey.png',
        name: 'PSG Home Jersey',
        title: 'PSG Jersey',
        price: '$107.99'
    },
    {
        id: 28,
        img: '/images/PSG Away Jersey.jpg',
        name: 'PSG Away Jersey',
        title: 'PSG Jersey',
        price: '$87.99'
    },
    {
        id: 29,
        img: '/images/PSG 3rd Jersey.jpg',
        name: 'PSG 3rd Jersey',
        title: 'PSG Jersey',
        price: '$87.99'
    },
    {
        id: 30,
        img: '/images/PSG Jacket.png',
        name: 'PSG Jacket',
        price: '$139.99'
    },
    {
        id: 31,
        img: '/images/PSG Hoodie.png',
        name: 'PSG Hoodie',
        price: '$111.99'
    },
    {
        id: 32,
        img: '/images/PSG Mask.png',
        name: 'PSG Mask',
        price: '$13.00'
    },
    {
        id: 33,
        img: '/images/FC Barcelona Home Jersey.jpg',
        name: 'FCB Home Jersey',
        title: 'FC Barcelona Jersey',
        price: '$114.99'
    },
    {
        id: 34,
        img: '/images/FC Barcelona Away Jersey.jpg',
        name: 'FCB Away Jersey',
        title: 'FC Barcelona Jersey',
        price: '$164.99'
    },
    {
        id: 35,
        img: '/images/FC Barcelona 3rd Jersey.jpg',
        name: 'FCB 3rd Jersey',
        title: 'FC Barcelona Jersey',
        price: '$174.99'
    },
    {
        id: 36,
        img: '/images/FC Barcelona Waterproof Jacket.png',
        name: 'FCB Waterproof Jacket',
        price: '$99.99'
    },
    {
        id: 37,
        img: '/images/FC Barcelona Bottle.png',
        name: 'FCB Bottle',
        price: '$14.99'
    },
    {
        id: 38,
        img: '/images/FC Barcelona Ball.png',
        name: 'FCB Ball',
        price: '$27.99'
    },
    {
        id: 39,
        img: '/images/FC Barcelona Shinguards.png',
        name: 'FCB Shinguards',
        price: '$18.00'
    },
    {
        id: 40,
        img: '/images/Manchester United Home Jersey.png',
        name: 'Man United Home Jersey',
        title: 'Man United Jersey',
        price: '$89.99'
    },
    {
        id: 41,
        img: '/images/Manchester United Away Jersey.jpg',
        name: 'Man United Away Jersey',
        title: 'Man United Jersey',
        price: '$74.99'
    },
    {
        id: 42,
        img: '/images/Manchester United 3rd Jersey.jpg',
        name: 'Man United 3rd Jersey',
        title: 'Man United Jersey',
        price: '$89.99'
    },
    {
        id: 43,
        img: '/images/Man United Running Trainers.png',
        name: 'Man United Running Trainers',
        price: '$110.00'
    },
    {
        id: 44,
        img: '/images/Manchester City Home Jersey.png',
        name: 'Man City Home Jersey',
        title: 'Man City Jersey',
        price: '$110.99'
    },
    {
        id: 45,
        img: '/images/Manchester City Away Jersey.png',
        name: 'Man City Away Jersey',
        title: 'Man City Jersey',
        price: '$99.99'
    },
    {
        id: 46,
        img: '/images/Manchester City 3rd Jersey.jpg',
        name: 'Man City 3rd Jersey',
        title: 'Man City Jersey',
        price: '$90.99'
    },
    {
        id: 47,
        img: '/images/Man City Keyring.webp',
        name: 'Man City Keyring',
        price: '$3.5'
    },
    {
        id: 48,
        img: '/images/Man City Mug.png',
        name: 'Man City Mug',
        price: '$4.8'
    },
    {
        id: 49,
        img: '/images/Man City Ball.webp',
        name: 'Man City Ball',
        price: '$18.00'
    },
    {
        id: 50,
        img: '/images/Man City Cap.webp',
        name: 'Man City Cap',
        price: '$21.70'
    },
    {
        id: 51,
        img: '/images/Liverpool Home Jersey.jpg',
        name: 'LFC Home Jersey',
        title: 'LFC Jersey',
        price: '$90.95'
    },
    {
        id: 52,
        img: '/images/Liverpool Away Jersey.png',
        name: 'LFC Away Jersey',
        title: 'LFC Jersey',
        price: '$69.95'
    },
    {
        id: 53,
        img: '/images/Liverpool 3rd Jersey.jpg',
        name: 'LFC 3rd Jersey',
        title: 'LFC Jersey',
        price: '$69.95'
    },
    {
        id: 54,
        img: '/images/Liverpool Men T-Shirt.jpg',
        name: 'LFC Men T-Shirt',
        price: '$27.95'
    },
    {
        id: 55,
        img: '/images/Liverpool Cap.jpg',
        name: 'LFC Cap',
        price: '$30.00'
    }
];

export default data;
