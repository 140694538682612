import React, { useState } from 'react'
import data from "./data";
import { Link } from "react-router-dom";

function Players() {
  const [value, setValue] = useState(0);
  const players = data.filter((item) => item.title === "player");
  const { background, img0, img1, img2, img3, img4, img5, img6 } = players[value];

  return (
    // for jerseys https://www.soccerpro.com

    <main className="players">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8 left">
        {players.map((player, index) => {
          const { id, img, name, number, img0 } = player;

          return (
                <div key={id} style={ index===value ? { background: background } : {} } onClick={() => setValue(index)}>
                  <img src={img} alt={name} />
                  <Link to=''>         
                    <img src={img0} alt=""/>
                  </Link>
                  <h2>{name}</h2>
                  <p>{number}</p>
                </div>
          );
        })}

              </div>
          <div className="col-lg-6 col-md-4 right">
          <Link to=''>         
            <img src={img0} alt="" />
          </Link>
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
            <img src={img4} alt="" />
            <img src={img5} alt="" />
            <img src={img6} alt="" />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Players;
