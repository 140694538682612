import React, { useState } from 'react';
import phoneIcon from './images/phone-icon.svg';
import mailIcon from './images/mail-icon.svg';
import userIcon from './images/user.svg';
import personIcon from './images/person.svg';
import messageIcon from './images/message.svg';

function Contact() {
    const [focus1, setFocus1] = useState(false);
    const [focus2, setFocus2] = useState(false);
    const [focus3, setFocus3] = useState(false);
    const [focus4, setFocus4] = useState(false);

    return (
        <div id='contact'>
            <div className="container">
                <h2>Send us a Message</h2>
                <p>We want to hear from you. Let us know how we can help</p>
                <div className='row justify-content-center mt-3'>
                    <div className='col-xl-5 col-md-6'>
                        <form>
                            <div className='row'>
                                <div className='col'>
                                    <div style={focus1 ? { border: '1px solid black' } : {}} className="mb-3 mt-3">
                                        {/* <img src={userIcon} alt="user icon" /> */}
                                        <img src={personIcon} alt="person icon" />
                                        <input onFocus={() => setFocus1(!focus1)} onBlur={() => setFocus1(!focus1)} type="name" className="form-control" id="name" placeholder="Enter your name" name="name"
                                            required />
                                    </div>

                                    <div style={focus2 ? { border: '1px solid black' } : {}} className="mb-3">
                                        <img src={mailIcon} alt="mail icon" />
                                        <input onFocus={() => setFocus2(!focus2)} onBlur={() => setFocus2(!focus2)} type="email" className="form-control" id="email" placeholder="Enter your email" name="email"
                                            required />
                                    </div>

                                    <div style={focus3 ? { border: '1px solid black' } : {}} className="mb-3">
                                        <img src={phoneIcon} alt="phone icon" />
                                        <input onFocus={() => setFocus3(!focus3)} onBlur={() => setFocus3(!focus3)} type="tel" className="form-control" id="phone-number" placeholder="Phone number"
                                            name="phone-number" />
                                    </div>

                                    <div style={focus4 ? { border: '1px solid black' } : {}} className="mb-3 mt-3">
                                        {/* <img src={messageIcon} alt="message icon" /> */}
                                        <textarea onFocus={() => setFocus4(!focus4)} onBlur={() => setFocus4(!focus4)} className="form-control" id="message" placeholder="Message" name="text" required />
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="btn mt-3">Submit</button>
                        </form>
                    </div>
                </div>

            </div>

            <div className="background-line">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div className='hidden-on-phone'></div>
                <div className='hidden-on-phone'></div>
                <div className='hidden-on-phone'></div>
                <div className='hidden-on-phone'></div>
                <div className='hidden-on-phone'></div>
            </div>

            <div className="background-circle">
            </div>
        </div>
    );
}

export default Contact;