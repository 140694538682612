import React from 'react';
import phoneIcon from './images/phone-icon.svg';
import mailIcon from './images/mail-icon.svg';

function Footer() {

    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h6>Connect with us</h6>
                            <div className="social-media">
                                <a href="https://twitter.com" className='twitter-icon'></a>
                                <a href="https://facebook.com" className='facebook-icon'></a>
                                <a href="https://instagram.com" className='instagram-icon'></a>
                                <a href="https://www.linkedin.com/in/ameer-ibrahim-7a035124a/" className='linkedin-icon'></a>
                            </div>
                        </div>

                        <div className="col" style={{ paddingTop: "40px" }}>
                            <div>
                                <img src={phoneIcon} alt="tel-icon" />
                                <a href="tel:+9647512062948">+964 751 206 2948</a>
                            </div>
                            <div>
                                <img src={mailIcon} alt="mail-icon" />
                                <a href="mailto: amierib013@gmail.com">amierib013@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='footer'>
                    <div>
                        <a href="#contact">Contact</a>
                        <a href="#faq">FAQ</a>
                        <a href="">Terms and conditions</a>
                    </div>
                    <p>© 2022 to Ameer Ibrahim</p>
                </div>
            </footer>
        </>
    );
}

export default Footer;