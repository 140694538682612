import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Navbar';
import Home from './Home';
import Shop from './Shop';
import Clubs from './Clubs';
import Players from './Players';
import MarcoReus from './players/Marco Reus';
import './App.css';

function App() {
  return (
    <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navbar />} >
              <Route index element={<Home />} />
              <Route path="shop" element={<Shop />} />
              <Route path="clubs" element={<Clubs />} />
              <Route path="players" element={<Players />} />
              <Route path="players/Marco Reus" element={<MarcoReus />} />
            </Route>
          </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
