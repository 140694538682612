import React, { useState, useEffect } from 'react';
import data from './data';
import { Link } from "react-router-dom";
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { BsArrowUpRight } from 'react-icons/bs';
import Footer from './Footer'
import FAQ from './FAQ'
import Contact from './Contact'

function Home() {
    const [index, setIndex] = useState(0);
    const players = data.filter((item) => item.title === 'player');
    const clubsJerseyArray = ['BVB Jersey', 'FC Bayern Jersey', 'FC Bayern Jersey', 'FC Barcelona Jersey', 'PSG Jersey', 'PSG Jersey', 'Man United Jersey', 'Man City Jersey', 'LFC Jersey'];
    const clubJersey = data.filter((item) => item.title === clubsJerseyArray[index]);

    const [startTouchX, setStartTouchX] = useState(0);
    const [isScrollingHorizontally, setIsScrollingHorizontally] = useState(false);


    const background = [];
    data.filter((item) => item.background).filter((item) => background.push(item.background))

    const playerLink = [];
    data.filter((item) => item.link).filter((item) => playerLink.push(item.link))

    const playerNames = [];
    players.map((player) => {
        playerNames.push(player.name)
    })

    useEffect(() => {
        const lastIndex = players.length - 1;
        if (index < 0) {
            setIndex(lastIndex);
        }
        if (index > lastIndex) {
            setIndex(0);
        }
    }, [index, players]);


    // let touchSlider = document.querySelector('.testimonials-container')
    function toucheSliderStart(e) {
        setStartTouchX(e.touches[0].pageX)
        setIsScrollingHorizontally(false)
    }

    function toucheSliderMove(e) {
        const touchX = e.touches[0].pageX;

        if (((touchX - startTouchX) > 40) && !isScrollingHorizontally) {
            setIsScrollingHorizontally(true)
            setIndex(index - 1)        } 
        else if (((startTouchX - touchX) > 40) && !isScrollingHorizontally) {
            setIsScrollingHorizontally(true)
            setIndex(index + 1)        } 
    }

    return (
        <>
            <main className='home'>
                <div className="container">
                    <div className="row row-1" onTouchStart={toucheSliderStart} onTouchMove={toucheSliderMove}>
                        <div className="col-md-6 left">
                            <h2>Keep calm and love football</h2>
                            <p>This product is excluded from promotinal discounts and offers</p>
                            <div className="bottom">
                                <div className='explore-more' style={{ background: background[index] }}>
                                    <Link to="/shop">Explore More</Link>
                                </div>


                                <div>
                                    <Link to='/players'>
                                        {playerNames[index]}
                                        <BsArrowUpRight />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 right">
                            <div className="img" style={{ background: background[index] }}>

                                {players.map((player, playerIndex) => {
                                    const { id, img, name } = player;

                                    let position = 'nextSlide';
                                    if (playerIndex === index) {
                                        position = 'activeSlide';
                                    }
                                    if (
                                        playerIndex === index - 1 ||
                                        (index === 0 && playerIndex === players.length - 1)
                                    ) {
                                        position = 'lastSlide';
                                    }

                                    return (
                                        <img src={img} alt={name} key={id} className={position} />
                                    );
                                })}

                                <button className="prev" onClick={() => setIndex(index - 1)} style={{ background: background[index] }}>
                                    <FiChevronLeft />
                                </button>

                                <button className="next" onClick={() => setIndex(index + 1)} style={{ background: background[index] }}>
                                    <FiChevronRight />
                                </button>
                            </div>
                        </div>

                    </div>

                    <div className="row row-2 justify-content-center">
                        <div className="top-picks">
                            <p>Top Picks</p>
                        </div>
                    </div>

                    <div className="row row-3">
                        {clubJersey.map((jersey) => {
                            const { id, img, name, price } = jersey;

                            return (
                                <div className="col-sm-4" key={id}>
                                    <img src={img} alt={name} />
                                    <h5 className='mt-3'>{name} </h5>
                                    <p className='price'>{price}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </main>

            <FAQ />
            <Contact />
            <Footer />

            {/* <div className="three">
                <img src={one} alt="one" className="one" />
                <img src={two} alt="two" className="two" />

            </div> */}
        </>
    );
}

export default Home;